<template>
  <img
    ref="image"
    :alt="alt"
    :class="isLoaded ? 'img-loaded' : ''"
    :src="src"
    draggable="false"
    @load="onLoad"
  >
</template>

<script>
export default {
  name: 'NoDragImage',
  props: {
    src: {
      // example @/assets/logo.png
      type: String,
      required: true,
    },
    scale: {
      type: [String, Number],
      default: 1,
      required: false,
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['load'],
  data() {
    return {
      isLoaded: false,
    };
  },
  methods: {
    onLoad() {
      let { scale } = this;

      if (scale === 'fit') {
        const image = this.$refs.image || null;

        if (image) {
          // Special case
          image.style.width = '100%';
          image.style.height = '100%';
        }

        this.isLoaded = true;
        this.$emit('load', image);

        return;
      }

      if (scale === 'normal') {
        scale = 0.7;
      } else if (scale === 'small') {
        scale = 0.6;
      }

      if (typeof (scale) !== 'number') {
        scale = parseFloat(scale);
      }

      if (!scale) {
        scale = 1;
      }

      const image = this.$refs.image || null;

      if (image && scale !== 1) {
        const currentWidth = image.naturalWidth;
        const currentHeight = image.naturalHeight;

        image.style.width = `${currentWidth * scale}px`;
        image.style.height = `${currentHeight * scale}px`;
      }

      this.isLoaded = true;
      this.$emit('load', image);
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  /* This stops dragging in chrome */
  -webkit-user-drag: none;

  opacity: 0;

  max-width: 100%;
  max-height: 100%;

  &.img-loaded {
    opacity: 1;
  }
}
</style>
